import { Outlet } from '@remix-run/react';
import { useTitle } from 'react-use';
import { IntercomProvider } from 'react-use-intercom';

import { useUserAnalytics } from '../analytics/user';
import { GlobalBlockedAccess } from '../components/Access/GlobalBlocked';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import config from '../config';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { makeTitle } from '../utils/common';

function Bootstrap() {
  useBootIntercom(useInstance(() => ['/onboarding']));

  return null;
}

export function Component() {
  useTitle(makeTitle('Onboarding'));

  const providers = [
    <GlobalBlockedAccess />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />

      <Bootstrap />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
